var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
        [
          _c(
            "c-card",
            {
              staticClass: "cardClassDetailForm",
              attrs: { title: "상세내용" },
            },
            [
              _c("template", { slot: "card-detail" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                  },
                  [
                    _c("div", {
                      domProps: {
                        innerHTML: _vm._s(_vm.popupParam.data.contents),
                      },
                    }),
                  ]
                ),
              ]),
            ],
            2
          ),
          _c(
            "c-card",
            {
              staticClass: "cardClassDetailForm",
              attrs: { title: "한국산업안전공단 원본링크" },
            },
            [
              _c("template", { slot: "card-detail" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                  },
                  [
                    _c(
                      "a",
                      {
                        attrs: {
                          href:
                            "https://www.kosha.or.kr/kosha/data/machine.do?mode=view&articleNo=" +
                            _vm.popupParam.data.boardno +
                            "&article.offset=1340&articleLimit=10",
                          target: "_blank",
                        },
                      },
                      [_vm._v("원본 링크(한국산업안전공단)")]
                    ),
                  ]
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }