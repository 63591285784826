<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <!--산업안전보건위원회 회의록 기본정보-->
        <c-card title="상세내용" class="cardClassDetailForm">
          <template slot="card-detail">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div v-html="popupParam.data.contents"></div>
            </div>
          </template>
        </c-card>
        <c-card title="한국산업안전공단 원본링크" class="cardClassDetailForm">
          <template slot="card-detail">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <a :href="'https://www.kosha.or.kr/kosha/data/machine.do?mode=view&articleNo='+popupParam.data.boardno+'&article.offset=1340&articleLimit=10'" target="_blank">원본 링크(한국산업안전공단)</a>
            </div>
          </template>
        </c-card>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'proceedings-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        data: '',
      }),
    },
  },
  data() {
    return {
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
    },
  }
};
</script>
